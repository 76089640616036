









































































import {Component, VModel} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import Area from '@/models/Area';
import Company from '@/models/Company';
import {namespace} from 'vuex-class';
import Task from '@/models/Task';
import {validationMixin} from 'vuelidate';
import {minLength, required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';

const CompanyStore = namespace('company');
const CustomerStore = namespace('customer');

// TODO: does not work now, but has no priority
const sameName = (value: Area[]) => {
  const areaNames: string[] = value.map((area) => area.name);
  for (const area of value) {
    if (areaNames.filter((areaName: string) => area.name === areaName).length > 1) {
      return false;
    }
  }
  return false;
};
@Component({
  mixins: [validationMixin],
  validations: {
    areas: {
      required,
      minLength: minLength(1),
      areaSameName: sameName,
    },
  },
})
export default class AreasTaskListComponent extends mixins(ErrorMessageHandlerMixin) {
  @CompanyStore.Getter('activeCompany')
  public _company!: Company;
  @VModel({required: true})
  public areas!: Area[];

  private expand: number[] = [];

  private get getMenuProps() {
    return {
      closeOnClick: false,
      closeOnContentClick: false,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 304,
      offsetY: true,
      bottom: true,
      rounded: 'lg',
      offsetOverflow: true,
      nudgeBottom: 10,
      contentClass: 'custom-form-select-menu',
    };
  }

  public mounted() {
    if (this.areas.length === 0) {
      this.addArea();
    }
    this.expand = this.areas.map((_, index) => index);
  }

  private inputEvent() {
    this.triggerValidation('areas');
  }


  /**
   * Add new Area to areasWithTasks array
   * @private
   */
  private addArea() {
    this.areas.push(new Area());
    this.addTask(this.areas.length - 1);
    this.expand.push(this.areas.length - 1);
    this.$emit('change');
  }

  public onEnterPress(areaIndex: number, taskIndex: number) {
    if (taskIndex === this.areas[areaIndex].tasks.length - 1) {
      this.addTask(areaIndex);
    }
    // necessary to bind this way
    this.$nextTick(() => {
      const el = AreasTaskListComponent.bindObject(this, areaIndex, taskIndex);
      el.focus();
    });
  }

  /**
   * this static function is necessary to set a ref inside an arrow function for the onEnterPress-function.
   * Apparently "this" of an arrow function is not the Vue-Component but a different one,
   * but you can still send "this" as a parameter
   */
  public static bindObject(vue: any, areaIndex: number, taskIndex: number) {
    return vue.$refs['task' + areaIndex][taskIndex + 1]!;
  }


  /**
   * Add new Task to areasWithTasks array
   */
  private addTask(areaIndex: number) {
    this.areas[areaIndex].tasks.push(new Task());
    this.triggerValidation('areas');
    this.$emit('change');
  }

  /**
   * Remove an area from the areasWithTasks array
   * @param areaIndex
   * @private
   */
  private removeArea(areaIndex: number) {
    this.areas.splice(areaIndex, 1);
    this.expand.splice(areaIndex, 1);
    this.triggerValidation('areas');
  }

  /**
   * Remove a task from an area in the areasWithTasks array
   * @param areaIndex
   * @param taskIndex
   * @private
   */
  private removeTask(areaIndex: number, taskIndex: number) {
    this.areas[areaIndex].tasks.splice(taskIndex, 1);
    this.triggerValidation('areas');
  }

  private onClick(areaIndex: number) {
    const index = this.expand.findIndex((value) => value === areaIndex);
    if (index !== -1) {
      this.expand.splice(index, 1);
    } else {
      this.expand.push(areaIndex);
    }
  }

  // currently not used, but could be useful in the future, when autocompletes are used again
  private areasFromDB: Area[] = [];
  private tasksFromDB: Task[] = [];

  /**
   * Filter the areas, so you can not select an area more than once
   * @param selectedArea The selected area needs to be in that array, to be a valid value in the autocomplete
   * @private
   */
  private filteredAreas(selectedArea: Area): Area[] {
    return this.areasFromDB.filter((area) => {
      // Name is unique, so filter with names. Ids are not equal after creating
      if (selectedArea && area.name === selectedArea.name) {
        return true;
      }
      return !this.areas.some((entry) => entry && entry.name === area.name);
    });
  }

  /**
   * Filter the tasks, so you can not select a task more than once per area
   * @param areaIndex
   * @param selectedTask The selected task needs to be in that array, to be a valid value in the autocomplete
   * @private
   */
  private filteredTasks(areaIndex: number, selectedTask: Task): Task[] {
    return this.tasksFromDB.filter((task) => {
      if (selectedTask && task.name === selectedTask.name) {
        return true;
      }
      return !this.areas[areaIndex].tasks.some((entry) => entry && entry.name === task.name);
    });
  }
}


